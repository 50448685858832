import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62')
];

export const server_loads = [2,6,7];

export const dictionary = {
		"/(app)": [10,[2],[3]],
		"/(app)/accelerator/onboarding": [12,[2],[3]],
		"/(app)/(admin)/announcements": [~11,[2],[3]],
		"/(app)/companies": [13,[2],[3]],
		"/(app)/companies/edit/[slug]": [~15,[2],[3]],
		"/(app)/companies/new": [~16,[2],[3]],
		"/(app)/companies/[slug]": [14,[2,4],[3]],
		"/components": [61],
		"/(auth)/email-confirmation": [46],
		"/(auth)/email-confirmation/failed": [47],
		"/(auth)/email-confirmation/request-verification": [48],
		"/(auth)/email-confirmed": [49],
		"/(app)/forum/[id=forum]": [~17,[2],[3]],
		"/(app)/founders": [18,[2],[3]],
		"/(app)/investors": [19,[2],[3]],
		"/(app)/investors/edit/[slug]": [~22,[2],[3]],
		"/(app)/investors/new": [~23,[2],[3]],
		"/(app)/investors/review": [24,[2],[3]],
		"/(app)/investors/review/[slug]": [~25,[2],[3]],
		"/(app)/investors/review/[slug]/edit/[id]": [~26,[2],[3]],
		"/(app)/investors/[slug]": [20,[2,5],[3]],
		"/(app)/investors/[slug]/reviews": [21,[2,5],[3]],
		"/login": [62],
		"/(app)/messages": [27,[2],[3]],
		"/(auth)/oauth-callback/github": [50],
		"/(auth)/oauth-callback/google": [51],
		"/(app)/onboarding": [~28,[2],[3]],
		"/(app)/posts": [~29,[2],[3]],
		"/(app)/posts/[id]": [30,[2,6],[3]],
		"/(app)/posts/[id]/comments/[commentId]": [31,[2,6],[3]],
		"/(app)/profiles": [32,[2,7],[3]],
		"/(app)/profiles/[username]": [33,[2,7,8],[3]],
		"/(app)/profiles/[username]/activity": [34,[2,7,8,9],[3]],
		"/(app)/profiles/[username]/activity/comments": [35,[2,7,8,9],[3]],
		"/(app)/projects": [~36,[2],[3]],
		"/(app)/projects/ended": [37,[2],[3]],
		"/(app)/projects/explore": [38,[2],[3]],
		"/(app)/projects/explore/[projectId]": [39,[2],[3]],
		"/(app)/projects/success": [40,[2],[3]],
		"/(app)/referrals": [41,[2],[3]],
		"/(auth)/refresh-session": [52],
		"/(app)/register": [~42,[2],[3]],
		"/(app)/reports": [43,[2],[3]],
		"/(auth)/reset-password": [53],
		"/(auth)/reset-password/link-expired": [54],
		"/(auth)/reset-password/link-sent": [55],
		"/(auth)/reset-password/new": [56],
		"/(auth)/reset-password/success": [57],
		"/(app)/settings": [44,[2],[3]],
		"/(auth)/signin": [58],
		"/(auth)/signin/verify-magic-link": [59],
		"/(auth)/signup": [60],
		"/(app)/support": [45,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';